@import 'colors';

.contractors-list {

  .filter-fields {
    .filter-field {
      &.type {
        width: 200px;
      }

      &.projectId {
        width: 250px;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table {

      .ant-table-thead {
        tr {

          th {
            border-top: 1px solid rgb(240, 240, 240);
          }

        }
      }

      tr {
        td, th {
          line-height: 16px;

          &.actions {
            width: 40px;
            text-align: center;
          }

          &.userId {
            min-width: 150px;

            .bot-icon {
              font-size: 23px;
              color: rgba(180, 180, 180, 0.8);
            }

          }

          &.typeName,
          &.telegram {
            width: 170px;
          }

          &.projectAndRoles {
            min-width: 120px;
          }

          &.name {
            min-width: 220px;
          }

          &.documents {
            //width: 220px;
            //min-width: 220px;

            .contractor-documents-icons {
              white-space: nowrap;
              .anticon,
              .doc-name {
                font-size: 26px;
                color: rgba(50,50,50,0.8);

                &:hover {
                  color: rgba(20,20,20,0.9);
                }

                > svg {
                  fill: currentColor;
                }

                &:first-child {
                  margin-left: 8px;
                }
                &:not(:last-child) {
                  margin-right: 8px;
                }

                &.not-filled {
                  color: $errorSecondary;

                  &:hover {
                    color: $errorColor;
                  }
                }
              }
            }
          }


        }


      }

      .ant-table-tbody {
        tr.ant-table-row {
          &:hover {
            td {
              cursor: pointer;
              background: $primaryColorHighlight;
            }
          }
        }
      }
    }
  }


}
