@import 'colors';


.document {
  padding: 4px 24px;
  display: flex;
  gap: 3em;

  &.INN,
  &.SNILS,
  &.OGRNIP {
    display: block;

    .document-file-list {
      margin-top: 20px;
    }
  }
}
