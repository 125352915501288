.birth-certificate-form {
  width: 600px;
  .ant-btn-link {
    padding: 0;
  }

  .form-field {

  }

}
