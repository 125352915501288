@import '../../../../colors';

.login-logs-list {
  .doc-duck-table {
    td {
      &.status {
        span {
          &.DECLINE {
            color: $errorColorLighter;
          }
        }
      }
    }
  }
}
