//@import 'src/colors';

.ant-modal.company_edit_modal,
.result_modal {
	:global(.ant-modal-content) {
		:global(.ant-modal-body) {
			:global(.ant-modal-confirm-body) {
				display: block;

				:global(.anticon.anticon-exclamation-circle),
				:global(.anticon.anticon-info-circle) {
					display: none;
				}

				:global(.ant-modal-confirm-title) {
					margin-bottom: 20px;
				}

				:global(.ant-modal-confirm-content) {
					.field {
						&:not(:last-child) {
							margin-bottom: 5px;
						}

						.ant-input {
							width: calc(100% - 120px);
						}
					}
				}
			}
		}
	}
}
