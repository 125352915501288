@import 'colors';

$labelWidth: 80px;

.contractor-main-details {
  .contractor-main-info {
    display: flex;
    gap: 1em;

    .contractor-fields,
    .print-requisites {
      min-width: 400px;

      h4 {
        margin: 0;
        font-size: 18px;
        margin-bottom: 6px;
      }
    }

    .print-requisites {
      padding-top: 6px;
    }
  }


}

