@import 'colors';

.App {
  .ant-app {
    .ant-layout{
      .ant-layout-content {
        .contractor-details {
           .page-header {
             display: flex;
             align-items: center;
             h4.project-and-role {
              font-size: 16px;
              margin-left: 6px;
            }

             .contractor-author {
               flex: 1 0 auto;
               padding-right: 12px;
               color: rgba(100, 100, 100, 0.8);
               //background: #6694bd;
               text-align: end;
             }
          }

          .contractor-main-details {
            padding: 4px 24px;


            //.contractor-fields {
            //  min-width: 400px;
            //  margin-right: 16px;
            //}
            //
            //.print-requisites {
            //  max-width: 600px;
            //}

          }


          .ant-tabs-tab {
            .tab-title {
              .anticon-exclamation-circle {
                color: $errorColorLight;
                margin-left: 2px;
              }
            }

            &.ant-tabs-tab-active {
              .tab-title {
                .anticon-exclamation-circle {
                  color: $errorColorLighter;
                }
              }
            }
          }


          .document {
            padding: 4px 24px;
            //display: flex;
            //gap: 3em;

            &.INN,
            &.SNILS {
              .ant-input {
                width: 200px;
              }
            }


            &.PASSPORT {
              display: flex;
              gap: 3em;
            }
          }

          .document-tab {
            display: flex;
            gap: 2em;
            align-items: flex-start;

            .field:not(.form-field-input) {
              .field-label {
                padding-left: 2px;
                font-weight: 500;
              }

              .ant-input {
                width: 200px;
              }
            }
          }

        }
      }
    }

  }
}
