.filter_form {
	//padding: 4px 4px 20px 4px;
	padding: 4px;
	//padding-bottom: 20px;
	border: 1px solid rgb(240, 240, 240);
	border-radius: 2px;
	background-color: rgb(250, 250, 250);

	margin-bottom: 20px;
	display: flex;
	width: 100%;
	align-items: end;
	gap: 0.5em;

	:global(.search-icon) {
		font-size: 18px;
		color: rgba(180, 180, 180, 0.9);

		:global(.anticon.anticon-search) {
			vertical-align: 0;
		}
	}


	:global(.filter-fields) {
		display: flex;
		//width: 100%;
		align-items: end;
		gap: 1em;
	}

	:global(.buttons) {
		display: flex;
		align-items: flex-start;
	}

	.search_icon {
		font-size: 18px;
		padding-top: 10px;
	}

	.field {
		:global(.ant-select),
		:global(.ant-input) {
			width: 100%;
		}
	}


	.field {
		.field_label {
			line-height: 16px;
		}
	}

	.extra_controls {
		padding-left: 20px;
	}

	:global(.ant-upload-list-text-container) {
		:global(.ant-upload-list-item) {
			margin-top: 0;
		}
	}
}
