@import 'colors';

$labelWidth: 100px;

.contractor-fields {
  .field {
    width: 100%;
    display: flex;
    gap: 1em;
    align-items: center;
    min-height: 32px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    .field-label {
      width: $labelWidth;
      //font-weight: 500;
      text-align: end;
      color: rgba(96, 96, 96, 0.95);
    }

    .ant-input-affix-wrapper,
    .ant-select{
      width: calc(100% - $labelWidth - 1em);
    }

    //.ant-select {
    //  width: 50%;
    //}

    &.invalid {
      .ant-input-affix-wrapper {
        border-color: $errorColorLighter;

        &:focus,
        &:active,
        &.ant-input-affix-wrapper-focused{
          border-color: $errorColor;
          box-shadow: 0 0 0 2px rgba(205, 32, 69, 0.16);
        }
      }
    }
  }

  &.readonly {

  }
}
