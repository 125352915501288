@import 'colors';

$labelWidth: 160px;

@mixin errorColor {
  border-color: red;

  &:focus,
  &:global(.ant-picker-focused) {
    box-shadow: 0 0 0 2px rgba(242, 43, 43, 0.22);
  }
}

.form_field {
  display: inline-block;
  width: 100%;

  .field {
    width: 100%;
    display: flex;
    align-items: flex-start;
    line-height: 16px;
    gap: 1em;

    //:global(.anticon.anticon-copy) {
    //  font-size: 16px;
    //}

    .label {
      //background: #4fb38c;
      width: $labelWidth;
      text-align: end;
      //line-height: 15px;
      word-break: break-word;
      white-space: break-spaces;
      color: rgba(96, 96, 96, 0.95);
    }

    .input {
      //background: pink;
      width: calc(100% - $labelWidth);

      :global(.ant-picker) {
        width: 50%;
      }
    }
    //
    //:global .ant-input-affix-wrapper,
    ////:global .ant-picker,
    //:global .ant-select {
    //  width: calc(100% - $labelWidth);
    //}
    //
    //:global(.ant-picker) {
    //  width: calc((100% - $labelWidth) / 2);
    //}
  }


  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:global(.invalid) {
    :global .ant-select:not(.ant-select-disabled),
    :global textarea:not(:disabled),
    :global .ant-picker:not(.ant-picker-disabled),
    :global .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      @include errorColor();

      :global(.ant-select-selector) {
        @include errorColor();
      }
    }
  }


  &:global(.readonly) {
      margin-bottom: 10px;

    :global(.ant-typography.field) {
      line-height: 18px;
      //padding-left:10px;

      &:global(.empty) {
        color: rgba(150, 150, 150, 0.9);
        font-style: italic;
      }

      //
      //:global(.ant-typography-copy) {
      //  color: rgba(200, 200, 200, 0.9);
      //}

      //&:hover {
      //  :global(.ant-typography-copy) {
      //    color: rgba(100, 100, 100, 0.9);;
      //
      //    &:hover {
      //      color: $linkBlueHover;
      //    }
      //  }
      //}
    }
  }


  .hint {
    font-size: 13px;
    color: gray;
    font-style: italic;
    padding-left: $labelWidth + 12px;
  }
}




