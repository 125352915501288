@import 'colors';

.App {
  height: 100vh;

  .ant-app {
    height: 100%;

    .ant-layout {
      height: 100%;

      .ant-layout-content {
        padding: 10px;
        overflow-y: auto;
        background-color: white;

        .page-header {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          height: 26px;

          > a {
            line-height: 26px
          }

          h4 {
            display: inline-block;
            margin: 0 0 0 16px;
            font-size: 18px;
          }

          .anticon.anticon-arrow-left {
            font-size: 16px;
          }
        }
      }

      .ant-layout-header {
        color: white;
        font-size: 18px;
      }
    }
  }

  .ant-btn-link {

  }


}


.ant-typography-copy {
  .anticon {
    color: rgba(190, 190, 190, 0.9);

    &:hover,
    &:focus,
    &:active {
      color: $linkColorHover;
    }
  }

  &.ant-typography-copy-success {
    .anticon {
      color: #52c41a;
    }
  }
}

.ant-spin-nested-loading {
  .ant-spin-text {
    color: $primaryColor;
  }

  .content {
    padding: 100px;
    //background: rgba(232, 242, 237, 0.25);
    border-radius: 4px;
  }

}

.ant-spin {
  .ant-spin-dot.ant-spin-dot-spin {
    color: $primaryColor;

    .ant-spin-dot-item {
      background: $primaryColor;
    }
  }
}

.ant-modal.ant-modal-confirm.ant-modal-confirm-info.modal-no-btns,
.ant-modal.ant-modal-confirm.ant-modal-confirm-confirm.modal-no-btns {
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body {
        //.ant-spin {
        //	color: $primaryColor;
        //
        //	.ant-spin-dot {
        //		.ant-spin-dot-item {
        //			background-color: $primaryColor;
        //		}
        //	}
        //}

        .anticon.anticon-info-circle,
        .anticon.anticon-exclamation-circle {
          display: none;
        }

        .ant-modal-confirm-title {
          flex: 0 0 100%;
        }

        .ant-modal-confirm-content {
          margin-inline-start: 0;
          min-height: 100px;
          //
          //.ant-input,
          //.ant-input-affix-wrapper{
          //	&:hover{
          //		&:not(.invalid) {
          //			border-color: $primaryColor;
          //		}
          //
          //	}
          //
          //	&:not(:disabled),
          //	&:not(.invalid){
          //
          //		&:hover {
          //			border-color: $primaryColor;
          //		}
          //
          //		&.ant-input-affix-wrapper-focused {
          //			border-color: $primaryColor;
          //			box-shadow: 0 0 0 2px rgba(79, 179, 140, 0.1);
          //		}
          //
          //	}
          //
          //	&:focus {
          //		border-color: $primaryColor;
          //		box-shadow: 0 0 0 2px rgba(79, 179, 140, 0.1);
          //	}
          //}
          //
          //.ant-select {
          //	.ant-select-selector {
          //		&:hover {
          //			border-color: $primaryColor;
          //		}
          //
          //		&:focus {
          //			border-color: $primaryColor;
          //			box-shadow: 0 0 0 2px rgba(79, 179, 140, 0.1);
          //		}
          //	}
          //
          //	&.ant-select-focused {
          //		&:not(.ant-select-disabled) {
          //			.ant-select-selector {
          //				border-color: $primaryColor;
          //				box-shadow: 0 0 0 2px rgba(79, 179, 140, 0.1);
          //			}
          //		}
          //	}
          //}
          //
          //.ant-radio-button-wrapper {
          //	&:not(.ant-radio-button-wrapper-disabled) {
          //
          //
          //		&:hover {
          //			border-color: $primaryColorHover;
          //			color: $primaryColorHover;
          //		}
          //
          //		&.ant-radio-button-wrapper-checked {
          //			border-color: $primaryColor;
          //			color: $primaryColor;
          //			&::before {
          //				background-color: $primaryColor;
          //			}
          //		}
          //	}
          //
          //}


        }

        .modal-buttons {
          padding-top: 20px;
          text-align: end;

          .ant-btn {
            &:not(:last-child) {
              margin-right: 10px;
            }

            &.ant-btn-primary {
              //&:not(:disabled) {
              //	background: $primaryColor;
              //	border-color: $primaryColor;
              //	//box-shadow: 0 2px 0 rgba(11, 99, 49, 0.16);
              //
              //	&:hover {
              //		background: $primaryColorHover;
              //		border-color: $primaryColorHover;
              //	}
              //}
            }

            &.ant-btn-default {
              //border-color: $primaryColor;
              //color: $primaryColor;

              //&:not(:disabled) {
              //	&:hover,
              //	&.focus {
              //		border-color: $primaryColorHover;
              //		color: $primaryColorHover;
              //	}
              //}
            }
          }
        }


      }

      .ant-modal-confirm-btns {
        display: none;
      }
    }

  }
}

.ant-modal.workspace-selector {
  .ant-modal-content {
    padding: 12px 16px;

    .ant-modal-confirm-body {
      .anticon.anticon-info-circle {
        opacity: 0;
        margin-right: 0;
        width: 1px;
      }

      .ant-modal-confirm-content {
        margin-inline-start: 1px;
        max-width: calc(100% - 1px);

        .ant-menu-item {
          padding-inline: 8px;

          .workspace-item {
            display: flex;
            align-items: center;
            gap: 0.5em;
          }
        }
      }
    }

    .ant-modal-confirm-btns {
      display: none;
    }
  }
}


.ant-tabs.ant-tabs-card {
  .ant-tabs-tab {
    padding: 6px 12px;

    .tab-title {
      .anticon {
        font-size: 18px;
      }

      &.not-filled {
        color: #d91313;
      }
    }

  }
}

.ant-notification-notice {
  .ant-notification-notice-content {
    .ant-notification-notice-with-icon {
      .ant-notification-notice-message {
        margin: 0;
      }
    }

  }
}
