$error-color: rgb(202, 14, 14);
$error-red: rgba(174, 32, 32, 0.8);
$error-red-darker: rgba(210, 10, 10, 0.7);
$error-red-dark: rgba(224, 51, 51, 0.25);

.login_container {
	display: flex;
	width: 100%;
	position: relative;
	align-items: center;
	justify-content: center;
	min-height: 100vh;

	.card {
		width: 440px;
		position: relative;


		.hint {
			margin-bottom: 20px;
			color: gray;
			text-align: center;
			font-size: 13px;
			//padding-left: 30px;
		}

		:global(.ant-input-affix-wrapper) {

		}

		.ok_button {
			margin-top: 20px;
		}

		:global(.ant-card-head-title) {
			text-align: center;
			//padding: 12px 0;
		}

		:global(.ant-input-affix-wrapper.invalid) {
			border-color: $error-red-darker;

			&:focus,
			&:active,
			&:hover {
				//border-color: $error-red-darker;
				box-shadow: 0 0 0 2px $error-red-dark;
			}
		}

		:global(.ant-card-body) {
			padding: 0;

			.content {
				padding: 24px 24px;
			}

			.button_container {
				text-align: center;
			}

			:global(.ant-divider-horizontal) {
				margin-top: 12px;
				margin-bottom: 6px;
			}
		}

		.footer {
			padding: 10px 14px;
			padding-bottom: 16px;
			font-size: 14px;
			//color: rgba(0,0,0,0.7);
			//text-align: center;
			//
			//:global(.ant-typography-copy) {
			//	opacity: 0;
			//}
			//
			//&:hover {
			//	:global(.ant-typography-copy) {
			//		opacity: 1;
			//	}
			//}
		}
	}
}
