@import 'colors';

.document-file-list {
  width: 600px;


  .loaded-files {
    position: relative;
    &:not(:first-child) {
      margin-top: 30px;
    }

    .file-wrapper {
      display: flex;
      gap: 1em;
      align-items: center;

      //&:not(:first-child) {
      //  margin-top: 10px;
      //}

      .buttons {
        min-width: 62px;
        .delete-btn,
        .download-btn {
          border: none;
          background: transparent;
          font-size: 16px;
          color: rgba(180, 180, 180, 0.7);

          &:hover,
          &:focus,
          &:active {
            color: $primaryColorHover;
          }

          //
          //&:not(:hover),
          //&:not(:focus),
          //&:not(:active){
          //  color: gray;
          //}

        }
      }
    }
  }
}
