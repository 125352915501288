@import '../../../colors';

.projects-list {
  position: relative;

  .ant-table-wrapper {
    //border: 1px solid rgb(240, 240, 240);
    //border-start-end-radius: 8px;
    //border-start-start-radius: 8px;
    //border-bottom-width: 0;
    //
    //.ant-pagination {
    //  padding: 0 4px;
    //}
  }

  .name-filter {
    width: 320px;
    margin-bottom: 12px;
  }

  .filter-form {
    .filter-field {
      &.name {
        width: 320px;
      }
    }
  }

  //.project-add-btn {
  //  position: absolute;
  //  z-index: 1000;
  //  top: 48px;
  //  left: 4px;
  //}


  .ant-table-wrapper {
    .ant-table {

      tr {
        td, th {
          line-height: 16px;

          &.inn,
          &.kpp {
            width: 110px;
          }

          &.createDate {
            width: 150px;
          }

          &.name {
            min-width: 220px;
          }

          &.owner {
            min-width: 250px;
          }

          &.actions {
            //width: 124px;

            //.ant-btn {
            //  border: none;
            //  background: transparent;
            //  font-size: 16px;
            //  color: #aeaeae;
            //
            //  &:hover,
            //  &:focus,
            //  &:active {
            //    color: $primaryColorHover;
            //  }
            //
            //  &:not(:last-child) {
            //    margin-right: 6px;
            //  }
            //}
          }

          &.numberOfContractor {
            line-height: 16px;

            .anticon.anticon-user {
              margin-right: 5px;
              font-size: 16px;
            }

            .empty {
              color: rgb(217, 217, 217);
            }
          }

          //&.ant-table-cell-row-hover {
          //	cursor: pointer;
          //	background-color: #4fb38c;
          //}
        }

      }

      .ant-table-tbody {
        tr.ant-table-row {
          &:hover {
            td {
              cursor: pointer;
              background: $primaryColorHighlight;
            }
          }
        }
      }

    }
  }


  &.loading {
    .ant-table-wrapper {
      //margin-top: 60px;
    }

  }
}
