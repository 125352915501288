@import 'colors';

.doc-duck-table {
  position: relative;

  .table {
    position: relative;
    border: 1px solid rgb(240, 240, 240);
    //border-start-end-radius: 8px;
    //border-start-start-radius: 8px;
    //border-bottom-width: 0;
    //border-top-width: 0;

    .ant-table-wrapper {
      .ant-table-container {
        .ant-table-thead {
          > tr {
            > th, td {
              border-top: 1px solid rgb(240, 240, 240);

              &:first-child {
                border-start-start-radius: 0;
              }

              &:last-child {
                border-start-end-radius: 0;
              }
            }


          }
        }
      }

      //.ant-table-pagination {
      //  display: none;
      //}

      .table-header {
        display: flex;
        gap: 2em;
        align-items: center;
      }


      .ant-table-thead {
        > tr {
          > th {
            font-weight: 500;
          }
        }
      }

      .ant-table-tbody {
        tr {
          &:nth-child(even) {
            td {
              background-color: rgba(240, 240, 240, 0.9);
            }
          }
          td.ant-table-cell {
            padding: 4px 8px;
            &.actions {
              text-align: center;

              .ant-btn {
                border: none;

                &:not(:last-child) {
                  margin-right: 6px;
                }

                &:not(:disabled) {
                  color: rgba(100, 100, 100, 0.7);

                  &:hover,
                  &:focus,
                  &:active {
                    color: $primaryColorHover;
                  }

                }


              }
            }
          }

          &:last-child,
          &.ant-table-placeholder{
            td {
              border-bottom-width: 0;
            }
          }
        }
      }



      .ant-table-pagination.ant-pagination {
        margin: 8px 0;
        padding: 0 4px;
      }


    }

  }

  &.with-toolbar {
    .table-toolbar {
      position: absolute;
      top: 0;
      left: 0;
      height: 40px;
      padding: 8px;
      z-index: 1000;
      //flex: 1 0 auto;

      .ant-btn-link {
        padding: 0;
        height: 24px;
      }
    }

    &.no-pagination,
    &.empty{
      .table-toolbar {
        position: relative;
        top: 0;
        left: 0;
      }
    }

    &.empty {
      .table {
        //border-bottom-width: 0;
      }
    }
  }

}

