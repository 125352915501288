$primaryColor: #0169cd;
$primaryColorHover: #1b81e2;
$primaryColorHighlight: rgba(139, 191, 241, 0.1);
$linkColor: #0b59a2;
$linkColorHover: #4097ea;
//$linkBlue: rgb(42, 8, 255);
//$linkBlueHover: rgb(78,92,255);

$errorColor: #ff0038;
$errorLight: #fb5169;
$errorColorLighter: rgba(226, 15, 47, 0.8);
$errorColorLight: rgba(205, 32, 69, 0.69);
$errorSecondary: rgba(211, 26, 54, 0.8);
