@import 'src/colors.scss';

header.ant-layout-header {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 2em;

  .logo {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
  }
  //
  //.user-info {

  .user-info {
    padding: 4px 12px;
    border-radius: 4px;
    background: #031e37;
    display: flex;
    height: 38px;
    align-items: center;
    gap: 1em;

    .workspace-name {
      color: #5fb0fc;

      &:hover,
      &:focus {
        cursor: pointer;
        color: rgb(115, 107, 252);
      }
    }
    .ant-dropdown-trigger.user-name {

      font-size: 15px;
      color: white;

      &:hover,
      &:focus {
        color: rgb(136, 129, 252);
      }
      //}

    }
  }



}

.ant-dropdown-menu.workspaces-menu {
  .ant-dropdown-menu-item {
    padding-inline: 8px;

    .workspace-item {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }
}
