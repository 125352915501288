@import 'colors';

.contractors-list {
  .app-table {
    position: relative;

    .filter-form {
      margin-bottom: 8px;
    }

    .table-toolbar {
      position: absolute;
      z-index: 10;
      top: 68px;
      left: 0;
    }
  }

  .ant-table-wrapper {
    td {
      &.documents {
        width: 250px;
        color: rgba(20,20,20,0.9);
        //span.not-filled {
        //	color: $errorColorLighter;

        .anticon {
          font-size: 26px;
          margin-right: 8px;
          color: rgba(90,90,90,0.9);
        }

        .not-filled {
          .anticon {
            color: red;
          }
        }
        //}
      }
    }
  }
}
