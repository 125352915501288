@import 'colors';

$labelWidth: 130px;

.number-form {
  .ant-btn-link {
    padding: 0;
  }

  .field {
    display: flex;
    align-items: center;
    gap: 1em;

    .label {
      width: $labelWidth;
      color: rgba(96, 96, 96, 0.95);
    }

    .ant-input {
      width: 200px;
    }

    .ant-typography-copy {
      .anticon {
        color: rgba(200, 200, 200, 0.9);

        &:hover,
        &:focus,
        &:active {
          color: $primaryColorHover;
        }
      }

      &.ant-typography-copy-success {
        .anticon {
          color: $primaryColor;
        }
      }
    }


    .ant-typography.readonly {
      &.empty {
        color: rgba(150, 150, 150, 0.9);
        font-style: italic;
      }
    }

    .ant-btn.edit-btn {
      color: gray;
      border: none;
      background: transparent;
      padding: 0;
      font-size: 15px;

      &:hover,
      &:focus,
      &:active {
        color: $primaryColorHover;
      }
    }
  }

  .hint {
    padding-left: 150px;
    font-size: 13px;
    color: gray;
  }

  &.readonly {
    .field {
      height: 32px;
    }
  }

}
