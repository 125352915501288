.organizations-list {
	position: relative;
	.add-organization-btn {
		position: absolute;
		left: 4px;
		top: 4px;
		z-index: 1000;
	}
	.ant-table-wrapper {

		.ant-table {
			tr {
				td, th {
					line-height: 16px;

					&.inn,
					&.kpp {
						width: 110px;
					}

					&.name {
						min-width: 220px;
					}

					&.owner {
						min-width: 250px;
					}

					&.actions {
						width: 40px;
						//
						//.ant-btn.ant-btn-sm {
						//	border: none;
						//}
					}
				}
			}
		}
	}

}
