//@import 'src/colors';

.ant-modal.contractor-in-project-edit-modal {
	.ant-modal-confirm-content {
		.contractor-select {
			width: 100%;
			margin-bottom: 15px;
		}
		//.field {
		//	//border: 1px solid red;
		//	//display: flex;
		//	//width: 100%;
		//	//gap: 1em;
		//
		//	&:not(:last-child) {
		//		margin-bottom: 10px;
		//	}
		//
		//	.field-label {
		//		display: inline-block;
		//		font-weight: 500;
		//		width: 150px;
		//	}
		//
		//	.ant-input,
		//	.ant-select{
		//		width: calc(100% - 150px);
		//	}
		//
		//	//.ant-select {
		//	//	flex: 1 0 auto;
		//	//}
		//}
	}
}
