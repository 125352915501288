@import 'src/colors';

.passport_form {
  min-width: 400px;
  //padding: 2px 6px;
  width: 580px;
  //margin-left: 20px;
  max-width: 600px;
  display: inline-block;

  :global(.ant-btn-link) {
    padding: 0;
  }

  :global(.buttons) {
    :global(.ant-btn) {
      &:not(:last-child) {
        margin-right: 16px;
      }

      &:global(.recognize-btn) {
        color: #cd0bcd;

        &:hover,
        &:focus{
          color: #f544f5;
        }
      }
    }
  }

  .button_container {
    margin-bottom: 40px;
    text-align: end;

    :global .ant-btn {
      &:not(:last-child) {
        margin-right: 20px;
      }

      :global .anticon {
        font-size: 16px;
      }
    }
  }

  :global(.form) {
    padding: 2px 6px;

    :global(.form-field) {
      :global(.ant-picker),
      :global(.ant-select) {
        width: 50%;
      }
    }
  }

}




