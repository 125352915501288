@import 'colors';

.roles-list {
  position: relative;

  .role-add-btn {
    padding: 0;
    height: 24px;
    position: absolute;
    left: 0;
    top: 56px;
    z-index: 1000;
  }

  .ant-list {
    width: 600px;

    .ant-list-item {
      .actions {
        .ant-btn {
          border: none;
          color: rgba(100, 100, 100, 0.7);

          &:hover {
            background: $primaryColorHighlight;
          }
        }
      }

      &:hover {
        background: $primaryColorHighlight;

        //.actions {
        //	.ant-btn {
        //		color: rgba(10, 10, 10, 0.8);
        //
        //		&:hover,
        //		&:focus {
        //			color: $primaryColorHover;
        //		}
        //	}
        //}
      }
    }

    .ant-list-pagination {
      margin: 12px 0;
    }
  }
}
