@import 'src/colors';

$labelWidth: 140px;
.bank_form {
  width: 580px;

  :global(.ant-btn-link) {
    padding: 0;
  }

  :global(.form-field) {
    :global(.field ) {
      align-items: center;
    }
  }

  //.bank_field {
  //	font-size: 15px;
  //	margin-bottom: 0;
  //
  //	&:not(:last-child) {
  //		margin-bottom: 5px;
  //	}
  //
  //	.label {
  //		width: $labelWidth;
  //		text-align: start;
  //		color: rgb(0, 31, 99);
  //		display: inline-block;
  //	}
  //
  //	:global(.ant-form-item-control) {
  //		:global(.ant-form-item-control-input) {
  //			min-height: 26px;
  //
  //			:global(.ant-typography) {
  //				font-size: 15px;
  //			}
  //		}
  //
  //	}
  //}
  //
  //.division {
  //	margin-top: 20px;
  //
  //	.division_address {
  //		font-size: 15px;
  //	}
  //}
  //
  //:global(.ant-typography-copy) {
  //	color: #cecece;
  //
  //	&:hover {
  //		color: $primaryColor;
  //	}
  //}
}
