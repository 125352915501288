//@import 'src/colors';

.company_edit_modal,
.result_modal {
  :global(.ant-modal-content) {
    padding: 12px 16px;

    :global(.ant-modal-body) {
      :global(.ant-modal-confirm-body) {
        display: block;


        :global(.anticon.anticon-exclamation-circle),
        :global(.anticon.anticon-info-circle) {
          display: none;
        }

        :global(.ant-modal-confirm-title) {
          margin-bottom: 20px;
        }

        :global(.ant-modal-confirm-content) {
          max-width: 100%;

          :global(.field) {
            padding-top: 6px;

            &:not(:last-child) {
              margin-bottom: 5px;
            }

            &:global(.required) {
              :global(.field-label) {
                &::after {
                  content: '*';
                  color: red;
                }
              }


            }

            &:global(.invalid) {
              :global(.ant-input-affix-wrapper) {
                border-color: red;

                &:hover,
                &:focus {
                  border-color: red;
                  box-shadow: 0 0 0 1px rgba(247, 66, 59, 0.3);
                }
              }
            }


            .ant-input {
              width: calc(100% - 120px);
            }
          }
        }
      }
    }
  }
}
