//@import 'src/colors';

.project-edit-modal {
	.field:not(:last-child) {
		margin-bottom: 10px;
	}
	.ant-select {
		width: 100%;
	}
}
