@import 'colors';

.project-details {
	.content {
		padding: 6px 16px;

		h4.sub-header {
			font-size: 16px;
			margin-top: 0;
		}

		.ant-alert.ant-alert-info {
			background-color: rgba(242, 249, 246, 0.7);
			border-color: $primaryColorHighlight;
			padding: 8px 12px;
			margin-bottom: 22px;

			.project-info {
				.field {
					display: flex;
					gap: 1em;
				}
			}
		}

	}

}
