.ant-layout-sider {
	.ant-layout-sider-children {
		.ant-menu {
			> li.ant-menu-item {
				padding-left: 24px;
			}

			> li.ant-menu-submenu {
				> .ant-menu-submenu-title {
					padding-left: 24px;
				}
			}
		}
	}


	.ant-layout-sider-trigger {
	}
}
